import { storeToRefs } from 'pinia';
import { useSwiperImage } from '~/features/cases/composables/useSwiperImage';

import { Breakpoints } from '~/constants/media.constants';
// counter multicast
import type { TPropertiesSize } from '~/types/Shared.types';
import { useCaseStore } from '~/features/cases/store/useCases.store';

type TSizeImg = { height: string; width: string };

const IMAGE_UPDATE_INTERVAL = 3000;

const one = '/static/case/multicast/one.png';
const two = '/static/case/multicast/two.png';
const free = '/static/case/multicast/free.png';
const four = '/static/case/multicast/four.png';

export function useSettingInfoCase(isMoreBreakpointXl: Ref<boolean>) {
  const store = useCaseStore();
  const { activeTypeDescriptionCase: activeTypeCase } = storeToRefs(store);
  const viewport = useViewport();

  const hasAnimation = ref<boolean>(false);

  const setSize = (width: string, height: string): TSizeImg => {
    return {
      height,
      width,
    };
  };

  // для скрытия картинки на мобильных ус-вах
  const isTablesAndMoreSize = computed<boolean>(() => {
    return viewport.breakpoint.value !== Breakpoints.sm;
  });

  // мультикаст множитель
  const counterImg = ref<string>(one);

  // мультификс множитель
  const multifixXCount = computed<string>(() => [one, two, free, four][store.caseMultiplier - 1]);

  // размер аватарки по ширине
  const sizeIconsTypeCase = computed<string>(() => {
    return isMoreBreakpointXl.value ? '148' : '98';
  });

  const heightIconTypeCase = computed<string>(() => (isMoreBreakpointXl.value ? '144px' : '96px'));

  const sizeTitle = computed<TPropertiesSize | undefined>(() => {
    if (!activeTypeCase.value) return;
    return isMoreBreakpointXl.value
      ? activeTypeCase.value.additionalCard?.title?.size.xl
      : activeTypeCase.value.additionalCard?.title?.size.sm;
  });

  //  размер цифр множителя
  const sizeCounterImg = computed<TSizeImg>(() => {
    return isMoreBreakpointXl.value ? setSize('40px', '40px') : setSize('32px', '32px');
  });

  const listImg: string[] = [one, two, free, four];
  useSwiperImage(counterImg, listImg, IMAGE_UPDATE_INTERVAL, hasAnimation);

  return {
    activeTypeCase,
    counterImg,
    hasAnimation,
    heightIconTypeCase,
    isTablesAndMoreSize,
    multifixXCount,
    sizeCounterImg,
    sizeIconsTypeCase,
    sizeTitle,
  };
}
