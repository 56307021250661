import { onUnmounted } from 'vue';

export function useSwiperImage(
  counterImg: Ref<string | undefined>,
  listImg: string[],
  duration = 3000,
  hasAnimation: Ref<boolean>,
): void {
  const activeIdx = ref<number>(0);
  const swipeCounter = (): void => {
    hasAnimation.value = false;
    counterImg.value = listImg[activeIdx.value];
    activeIdx.value >= listImg.length - 1 ? (activeIdx.value = 0) : activeIdx.value++;
    hasAnimation.value = true;
  };
  if (!import.meta.client) return;
  const interval = setInterval(() => swipeCounter(), duration);

  onUnmounted(() => clearInterval(interval));
}
