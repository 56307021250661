<template>
  <div class="shared-case-description">
    <div v-if="props.isLeftSide" class="left-side" :style="leftSideStyle">
      <div class="left-side__img-container">
        <UiImage
          v-if="props.leftSideObject?.image"
          :src="props.leftSideObject.image"
          :height="props.leftSideObject.imgHeight"
          :width="props.leftSideObject.imgWidth"
          style="border-radius: 16px"
        />
      </div>
      <div class="left-side__text-container">
        <slot name="left-side-title" />
        <slot name="left-side-description" />
      </div>
    </div>
    <div v-if="!props.isLeftSide" class="right-side" :style="rightSideStyle">
      <div class="right-side__img-container">
        <UiImage
          v-if="props.rightSideObject?.image"
          :src="props.rightSideObject.image"
          :height="props.rightSideObject.imgHeight"
          :width="props.rightSideObject.imgWidth"
          style="border-radius: 16px"
        />
      </div>
      <div class="right-side__text-container">
        <div class="right-side_title">
          <slot name="right-side-title" />
          <div v-if="props.rightSideObject?.isShowSubtitle" class="right-side_sub-title" :style="rightSubTitleStyle">
            <slot name="right-side-subtitle" />
          </div>
        </div>
        <div class="right-side_description" :style="caseDescriptionStyles">
          <slot name="right-side-description" />
        </div>
        <div class="right-side_prices">
          <slot name="right-side_prices"></slot>
        </div>
        <div class="right-side_btn">
          <slot name="right-side-btn"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import type { CSSProperties } from 'vue';
import { computed } from 'vue';
import type { ISharedCaseDescriptionProps } from './SharedCaseDescription.types';
import { useCaseStore } from '~/features/cases/store/useCases.store';

const caseStore = useCaseStore();

const { isEventCase, isDailyCase } = storeToRefs(caseStore);

const EVENT_CASE_DESCRIPTION_LINES_COUNT = 2;
const DEFAULT_CASE_DESCRIPTION_LINES_COUNT = 4;

const props = defineProps<ISharedCaseDescriptionProps>();

const rightSideStyle = computed(() => {
  const styles: CSSProperties = {};
  if (props.rightSideObject?.bg) {
    // @ts-expect-error TODO remove or refactor
    styles.background = props.rightSideObject.bg;
  }
  return styles;
});

const leftSideStyle = computed(() => {
  const styles: CSSProperties = {};
  if (props.leftSideObject?.bg) {
    // @ts-expect-error TODO remove or refactor
    styles.background = props.leftSideObject.bg;
  }
  return styles;
});

const rightSubTitleStyle = computed(() => {
  const styles: CSSProperties = {};
  if (props.rightSideObject?.subtitleBackground) {
    // @ts-expect-error TODO remove or refactor
    styles.background = props.rightSideObject.subtitleBackground;
  }
  return styles;
});

const caseDescriptionStyles = computed<CSSProperties>(() => ({
  '--lines-count':
    isEventCase.value || isDailyCase.value ? EVENT_CASE_DESCRIPTION_LINES_COUNT : DEFAULT_CASE_DESCRIPTION_LINES_COUNT,
}));
</script>

<style src="./SharedCaseDescription.scss" scoped lang="scss"></style>
