<template>
  <div
    :class="['carousel-cases-wrapper', { 'wrapper-animation': carouselsCount && carouselsCount > 1 }]"
    :style="cssVars"
  >
    <slot name="carousel" />
    <div ref="$buttons" :class="buttonClasses">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ICarouselCasesWrapper } from './CarouselCasesWrapper.types';
import { Breakpoints } from '~/constants/media.constants';
import { useCasesUiStore } from '~/features/cases/store/useCases.ui.store';

const casesUIStore = useCasesUiStore();
const { $buttons } = storeToRefs(casesUIStore);

const props = withDefaults(defineProps<ICarouselCasesWrapper>(), {
  carouselsCount: 1,
});

const viewport = useViewport();

const wrapperHeight = computed<string>(() => {
  if (props.isMulticast) return 'auto';
  if (!props.isAuth) return viewport.isGreaterOrEquals(Breakpoints.md) ? '217px' : '164px';
  if (props.available) return '201px';
  return viewport.isGreaterOrEquals(Breakpoints.md) ? '280px' : '240px';
});

const carouselHeightByViewport = computed<number>(() => (viewport.isGreaterThan(Breakpoints.md) ? 160 : 116));

const carouselsCountToCalculateWrapperHeight = computed<number>(() =>
  props.carouselsCount > 1 ? props.carouselsCount - 1 : props.carouselsCount,
);

const wrapperMinHeight = computed<string>(() => {
  if (props.isQuick) {
    return `${carouselHeightByViewport.value}px`;
  } else {
    return (props.isMulticast || props.isMultifix || props.isMultiopen) && props.carouselsCount
      ? `${carouselsCountToCalculateWrapperHeight.value * carouselHeightByViewport.value}px`
      : 'auto';
  }
});

const cssVars = computed((): Record<string, string> => {
  return {
    '--carousels-count': props.carouselsCount.toString(),
    '--wrapper-height': wrapperHeight.value,
    '--wrapper-min-height': wrapperMinHeight.value,
    '--buttons-transition-fn': props.isMulticast || props.isMultifix ? 'top 0.8s cubic-bezier(0.65, 0, 0.35, 1)' : '',
  };
});

const buttonClasses = computed(() => {
  return {
    'carousel-cases-wrapper__buttons': true,
    [`carousel-cases-wrapper__buttons_carousels-count_${props.carouselsCount}`]: props.carouselsCount % 2 === 0,
    'carousel-cases-wrapper__buttons--multi': props.carouselsCount > 1,
  };
});
</script>

<style scoped lang="scss" src="./CarouselCasesWrapper.scss"></style>
